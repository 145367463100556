import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner, InputGroup, Form,
} from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  registeredRegion, libraryProduct, regionalLibrary as loadRegionalLibrary,
  productCategories as loadProductCategories, uploadImage,
} from '../../assets/api/axios';
import {
  CustomTable, CustomModal, Svg, CustomDropdown,
} from '../../component/common';
import { Constant } from '../../utilities';
import {
  getProductCategories, getRegesteredRegions, setProductCategories,
  setRegesteredRegions,
} from '../../utilities/Storage';
import { dateString, getHoursDiff, getMonolithErrorText } from '../../utilities/Utils';
import permission from '../../access&permissions/permission';

const isProdEnv = process.env.REACT_APP_ENV === 'production';
const isReleaseEnv = process.env.REACT_APP_ENV === 'release';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p,
  } = param;
  const {
    searchText = '',
    skuCodes = '',
    regionIn = '',
    productIds = '',
    productCategories = '',
    hasRegionalPrice = '',
    isCurated = 'true',
    isPrimary = '',
    curatedProductGroupId = '',
    curatedProductGroupMapId = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
    searchText,
    skuCodes,
    regionIn,
    productIds,
    productCategories,
    hasRegionalPrice,
    isCurated,
    isPrimary,
    curatedProductGroupId,
    curatedProductGroupMapId,
  });
};

class LibraryProducts extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    let productCategories = getProductCategories();
    if (productCategories) {
      const hrDiff = getHoursDiff(productCategories.lastModifiedTime, new Date());
      if (hrDiff > 24) {
        productCategories = null;
      }
    }
    let regions = getRegesteredRegions();
    if (regions) {
      const hrDiff = getHoursDiff(regions.lastUpdatedTime, new Date());
      if (hrDiff > 24) {
        regions = null;
      }
    }
    const { userPermission } = this.props;
    this.permissions = {
      ratroactive: userPermission.includes(permission.LIBRARY_PRODUCTS_WRITE_RETROACTIVE),
      write: userPermission.includes(permission.LIBRARY_PRODUCTS_WRITE),
    };
    this.state = {
      regions: (regions && regions.regions) || null,
      productCategories: (productCategories && productCategories.categories) || null,
      libraryProducts: [],
      libraryProductsCount: 0,
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      curatedGroupName: param.q,
      loading: true,
      error: false,
      submitting: false,
      submitError: false,
      submitErrorMsg: '',
      param,
      newParams: param,
      viewRegionalLibraryMetaModal: false,
      libraryProductsMetaData: null,
      libraryProductsMetaDataLoadStatus: '',
      selectedRegLibProdToPerformAction: null,
      showPerformingActionModal: false,
      syncType: 'IGNORE',
      reason: '',
      mrp: '',
      sellingPrice: '',
      spError: false,
      libraryProductId: '',
      region: '',
      patchLoader: false,
      patchError: false,
      patchItem: null,
      showLesserSPAlert: false,
      selectImageId: '',
      patchErrorMsg: '',
    };
    this.source = Axios.CancelToken.source();
    this.persistedPatchItem = null;
    this.hiddenFileInput = React.createRef();
    this.isForceAdd = userPermission.includes(permission.FORCE_ADD_BULK_UPLOAD);
  }

  componentDidMount = () => {
    const { productCategories, regions } = this.state;

    if (!regions) {
      registeredRegion('GET').then((res) => {
        this.setState({ regions: res.data.results });
        setRegesteredRegions({
          regions: res.data.results,
          lastUpdatedTime: new Date(),
        });
      }).catch(() => {});
    }

    if (!productCategories) {
      loadProductCategories('GET', { requiredKeys: 'displayName,id,children' }).then((res) => {
        setProductCategories({
          lastModifiedTime: new Date(),
          categories: res.data.results,
        });
        this.setState({
          productCategories: res.data.results,
        });
      }).catch(() => {});
    }
    this.handleLoad();
  }

  applyFilters = () => {
    const { newParams } = this.state;
    this.handleRequestProcessing({
      ...newParams,
      p: 1,
    });
  }

  resetfilters = () => {
    this.handleRequestProcessing({
      regionIn: '',
      searchText: '',
      skuCodes: '',
      productIds: '',
      productCategories: '',
      hasRegionalPrice: '',
      isCurated: 'true',
      isPrimary: '',
      p: 1,
    });
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.setState((state) => ({
      newParams: { ...state.newParams, ...data },
    }));
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState((state) => ({
      newParams: { ...state.newParams, [name]: value },
    }));
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, searchText, skuCodes, regionIn, productIds,
      productCategories, hasRegionalPrice, isCurated, isPrimary,
      curatedProductGroupId, curatedProductGroupMapId,
    } = newParam;
    const offset = (p - 1) * l;
    libraryProduct(
      'GET',
      null,
      '',
      {
        view: 'DEFAULT',
        offset: `${offset}`,
        limit: l,
        searchText,
        skuCodes,
        regionIn,
        ids: productIds,
        categoryIds: productCategories,
        hasRegionalPrice,
        isCurated,
        isPrimary,
        curatedProductGroupId,
        curatedProductGroupMapId,
      },
      this.source.token,
    ).then((res) => {
      this.setState({
        libraryProducts: res.data.results,
        loading: false,
        libraryProductsCount: res.data.count,
        hasNext: res.data.hasNext,
        hasPrevious: res.data.hasPrevious,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
        newParams: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      ...name === 'sellingPrice' ? { spError: false } : {},
      [name]: value,
    });
  }

  handleRadioButtonChange = (event) => {
    const { name } = event.target;
    this.setState((state) => ({
      [name]: !state[name],
    }));
  }

  getPayloadToPerformAction = (action) => {
    const {
      reason, selectedRegLibProdToPerformAction,
      syncType, libraryProductId, region, sellingPrice, mrp,
    } = this.state;
    let body = {};
    switch (action) {
      case 'ARCHIVE_REGIONAL_PRICE':
        body = { id: selectedRegLibProdToPerformAction.regionalProductMapId, reason };
        break;
      case 'ADD_REGIONAL_PRICE':
        body = {
          libraryProductId,
          region,
          sellingPrice,
          mrp,
        };
        break;
      default:
        break;
    }
    return ({
      action,
      body,
      syncJobTriggerAt: syncType,
    });
  }

  performAction = () => {
    const {
      selectedRegLibProdToPerformAction, action, mrp, sellingPrice,
    } = this.state;
    if (mrp < sellingPrice) {
      this.setState({ spError: true });
      return;
    }
    this.setState({
      submitting: true,
      submitError: false,
      submitErrorMsg: '',
    });

    const body = this.getPayloadToPerformAction(action);
    loadRegionalLibrary(
      'POST',
      {},
      body,
      this.source.token,
    ).then((res) => {
      const { libraryProducts: newlibraryProducts } = this.state;
      if (action === 'ARCHIVE_REGIONAL_PRICE') {
        const index = newlibraryProducts.findIndex((item) => (
          item.regionalProductMapId === selectedRegLibProdToPerformAction.regionalProductMapId
        ));
        newlibraryProducts.splice(index, 1);
      } else {
        newlibraryProducts.splice(0, 0, res.data);
      }
      if (res.status === 200) {
        this.setState({
          submitting: false,
          libraryProducts: newlibraryProducts,
          selectedRegLibProdToPerformAction: null,
          showPerformingActionModal: false,
          syncType: 'IGNORE',
          reason: '',
          action: '',
          mrp: '',
          sellingPrice: '',
          region: '',
          libraryProductId: '',
          spError: false,
        });
      } else {
        throw new Error();
      }
    }).catch((err) => {
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.server_response
      ) {
        this.setState({
          submitError: true,
          submitting: false,
          submitErrorMsg: err.response.data.server_response,
        });
        return;
      }
      this.setState({
        submitError: true,
        submitting: false,
        submitErrorMsg: 'Oops! Something went wrong!',
      });
    });
  }

  getTitleForAction = (action) => {
    switch (action) {
      case 'ADD_REGIONAL_PRICE':
        return 'Add New Regional Price';
      case 'ARCHIVE_REGIONAL_PRICE':
        return 'Archive Regional Price';
      default:
        return '';
    }
  }

  patchProductField = (continueAnyway = false) => {
    const { patchItem, libraryProducts } = this.state;
    const finalPatchItem = patchItem || this.persistedPatchItem;
    const selectedData = libraryProducts.find((item) => item.id === finalPatchItem.id);
    if ((finalPatchItem.field === 'sellingPrice') && !continueAnyway && (
      (((selectedData.mrp - finalPatchItem.value) * 100) / selectedData.mrp) >= 65
    )) {
      this.persistedPatchItem = finalPatchItem;
      this.setState({
        showLesserSPAlert: true,
        patchLoader: false,
      });
      return;
    }
    libraryProduct(
      'PATCH',
      {
        [finalPatchItem.field]: finalPatchItem.value,
        forceAdd: this.isForceAdd,
      },
      finalPatchItem.id,
    ).then(() => {
      this.persistedPatchItem = null;
      this.setState({
        patchItem: null,
        patchLoader: false,
        patchError: false,
        patchErrorMsg: '',
        libraryProducts: libraryProducts.map((product) => (
          product.id === finalPatchItem.id
            ? { ...product, [finalPatchItem.field]: finalPatchItem.value }
            : product
        )),
      });
    }).catch((error) => {
      const patchErrorMsg = getMonolithErrorText(error);
      this.setState({
        patchErrorMsg,
        patchLoader: false,
        patchError: true,
      });
    });
  }

  handleEditProduct = (continueAnyway = false) => {
    this.setState({
      patchLoader: true,
      patchError: false,
      patchErrorMsg: '',
    }, () => {
      this.patchProductField(continueAnyway);
    });
  }

  handleImageOnChange = (event) => {
    const { selectImageId } = this.state;
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('img', file);
    uploadImage(
      'POST',
      formData,
    ).then((res) => {
      this.setState({
        patchItem: {
          id: selectImageId,
          field: 'image',
          value: res.data.url,
        },
      }, this.handleEditProduct);
    }).catch(() => {});
  }

  render() {
    const {
      param, loading, error, libraryProducts, productCategories,
      rowsPerPage, page, submitting, submitError, libraryProductsCount,
      hasNext, hasPrevious, sellingPrice, mrp, spError, newParams,
      action, showPerformingActionModal, selectedRegLibProdToPerformAction,
      syncType, reason, regions, submitErrorMsg, region, libraryProductId,
      patchItem, patchLoader, patchError, showLesserSPAlert, patchErrorMsg,
    } = this.state;

    const { history } = this.props;

    const getShowCase = (data, field) => {
      if (patchItem && (patchItem.id === data.id)
        && (patchItem.field === field)) {
        let isValid = false;
        if (patchItem.value) {
          if (field === 'mrp' && (
            patchItem.value >= data.sellingPrice
          )) {
            isValid = true;
          }
          if (field === 'sellingPrice' && (
            patchItem.value <= data.mrp
          )) {
            isValid = true;
          }
          if (
            field === 'displayName'
            || field === 'unit'
          ) {
            isValid = true;
          }
        }
        if (patchLoader) {
          return (
            <Spinner
              animation="border"
              variant="primary"
              size="sm"
            />
          );
        }
        if (patchError) {
          return (
            <div>
              <div
                className="d-flex"
              >
                <Button
                  className="fs-01 rounded-0 mr-2"
                  size="sm"
                  onClick={this.handleEditProduct}
                >
                  <Svg
                    svg="retry"
                    width="0.8rem"
                    fill={Constant.Color.WHITE}
                  />
                </Button>
                <Button
                  variant="secondary"
                  className="fs-01 rounded-0"
                  size="sm"
                  onClick={() => {
                    this.setState({
                      patchItem: null,
                      patchLoader: false,
                      patchError: false,
                      patchErrorMsg: '',
                    });
                  }}
                >
                  <Svg
                    svg="arrow"
                    width="0.8rem"
                    fill={Constant.Color.BLACK}
                    transform="rotate(-90deg)"
                  />
                </Button>
              </div>
              <div
                className="fs-01 text-danger"
              >
                {patchErrorMsg}
              </div>
            </div>

          );
        }
        return (
          <input
            type="text"
            value={patchItem.value}
            size={patchItem.value.toString().length || 1}
            onChange={(e) => {
              if (field === 'displayName') {
                this.setState({
                  patchItem: {
                    id: data.id,
                    field,
                    value: e.target.value || '',
                  },
                });
              } else {
                const { value } = e.target;
                const numVal = Number(value);
                if (value === '' || (numVal && numVal > 0)) {
                  this.setState({
                    patchItem: {
                      id: data.id,
                      field,
                      value: numVal || '',
                    },
                  });
                }
              }
            }}
            onKeyPress={(e) => {
              if (e.which === 13 && isValid
                && patchItem.value !== data[field]) {
                this.handleEditProduct();
              }
            }}
            onBlur={() => {
              this.setState({
                patchItem: null,
              });
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className={`fs-01 text-medium ${isValid
              ? '' : 'border-danger'}`}
          />
        );
      }
      return (
        <Button
          variant="link"
          className="fs-01 text-medium p-0"
          onClick={() => {
            this.setState({
              patchItem: {
                id: data.id,
                field,
                value: data[field],
              },
            });
          }}
          disabled={this.permissions
            && !(
              this.permissions.ratroactive
              || this.permissions.write
            )}
        >
          {data[field] || '--'}
        </Button>
      );
    };

    const getImageShowCase = (data, field) => {
      if (patchItem && (patchItem.id === data.id)
      && (patchItem.field === field)) {
        if (patchLoader) {
          return (
            <div
              className="d-flex justify-content-center"
            >
              <Spinner
                animation="border"
                variant="primary"
                size="sm"
              />
            </div>
          );
        }
      }
      return (
        <div
          className="text-center"
        >
          <Button
            variant="link"
            onClick={() => {
              this.setState({
                selectImageId: data.id,
              }, this.hiddenFileInput.current.click());
            }}
            disabled={this.permissions
              && !(
                this.permissions.ratroactive
                || this.permissions.write
              )}
            className="p-0"
          >
            <img src={data.image} weight={100} height={100} alt="product" />
          </Button>
          <input
            type="file"
            className="d-none"
            ref={this.hiddenFileInput}
            onChange={(event) => {
              this.handleImageOnChange(event);
            }}
          />
        </div>
      );
    };

    const headers = [
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'displayName',
        displayText: 'Display Name',
        renderer: (data) => getShowCase(data, 'displayName'),
      },
      {
        key: 'sellingPrice',
        displayText: 'SP',
        renderer: (data) => getShowCase(data, 'sellingPrice'),
      },
      {
        key: 'mrp',
        displayText: 'MRP',
        renderer: (data) => getShowCase(data, 'mrp'),
      },
      {
        key: 'unit',
        displayText: 'Unit',
        // renderer: (data) => getShowCase(data, 'unit'),
      },
      {
        key: 'uom',
        displayText: 'UOM',
        renderer: (data) => {
          if (
            patchItem && (patchItem.id === data.id)
            && (patchItem.field === 'uom')
          ) {
            if (patchLoader) {
              return (
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                />
              );
            }
            if (patchError) {
              return (
                <>
                  <div
                    className="d-flex"
                  >
                    <Button
                      className="fs-01 rounded-0 mr-2"
                      size="sm"
                      onClick={this.handleEditProduct}
                    >
                      <Svg
                        svg="retry"
                        width="0.8rem"
                        fill={Constant.Color.WHITE}
                      />
                    </Button>
                    <Button
                      variant="secondary"
                      className="fs-01 rounded-0"
                      size="sm"
                      onClick={() => {
                        this.setState({
                          patchItem: null,
                          patchLoader: false,
                          patchError: false,
                          patchErrorMsg: '',
                        });
                      }}
                    >
                      <Svg
                        svg="arrow"
                        width="0.8rem"
                        fill={Constant.Color.BLACK}
                        transform="rotate(-90deg)"
                      />
                    </Button>
                  </div>
                  <div
                    className="fs-01 text-danger"
                  >
                    {patchErrorMsg}
                  </div>
                </>
              );
            }
          }
          return (
            <select
              value={data.uom}
              className="p-1"
              onChange={(e) => {
                if (e.target.value !== '') {
                  this.setState({
                    patchItem: {
                      id: data.id,
                      field: 'uom',
                      value: e.target.value,
                    },
                  }, () => {
                    this.handleEditProduct();
                  });
                }
              }}
              disabled
            >
              <option value="">Select</option>
              <option value="Units">Units</option>
              <option value="GMs">GMs</option>
              <option value="KGs">KGs</option>
              <option value="ML">ML</option>
              <option value="L">L</option>
            </select>
          );
        },
      },
      {
        key: 'internalSkuCode',
        displayText: 'SKU Code',
      },
      {
        key: 'image',
        displayText: 'Image',
        renderer: (data) => getImageShowCase(data, 'image'),
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (
          <>{dateString(data.createdOn)}</>
        ),
      },
      {
        key: 'primaryCategoryCode',
        displayText: 'Primary Category Code',
      },
      {
        key: 'lastUpdatedBy',
        displayText: 'Last Updated By',
      },
      {
        key: 'previousJobDetail',
        displayText: 'Prev. Job Detail(Clickable Links)',
        renderer: (data) => (
          <>
            {
              (data.previousJobDetail).map((item) => (
                <div>
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="pb-2"
                    style={{
                      color: item.color,
                    }}
                  >
                    {
                      item.text
                    }
                  </a>
                </div>
              ))
            }
          </>
        ),
      },
      {
        key: 'edit-redirect',
        displayText: 'Edit Product',
        renderer: (data) => (
          <a
            // eslint-disable-next-line no-nested-ternary
            href={`https://catadmin${isProdEnv ? '' : isReleaseEnv ? '-release' : '-staging'}.mpaani.com/dashboard/catalog-management/library-product/${data.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className=""
          >
            <Svg
              svg="editIcon"
              width={16}
              height={16}
              stroke={Constant.Color.DARK}
            />
          </a>
        ),
      },
      {
        key: 'view-curated-gr-map',
        displayText: 'View Catalogues With Product',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              if (data.isCurated) {
                history.push(`/smart-catalogue-map?libraryProductId=${data.id}`);
              }
            }}
            className="p-0 fs-01"
          >
            {data.isCurated ? 'View' : '---'}
          </Button>
        ),
      },
      {
        key: 'regional-prices',
        displayText: 'Regional Prices',
        renderer: (data) => (
          <Button
            variant="link"
            onClick={() => {
              history.push(`/library-product-regional-price?libraryProductId=${
                data.id
              }&lpname=${
                data.displayName
              }&addNewModal=${
                !data.hasRegionalPrice
                && this.permissions
                && (
                  this.permissions.ratroactive
                  || this.permissions.write
                )
              }`);
            }}
            className="p-0 fs-01"
          >
            {
              !data.hasRegionalPrice
              && this.permissions
              && (
                this.permissions.ratroactive
                || this.permissions.write
              ) ? 'ADD' : 'VIEW'
            }
          </Button>
        ),
      },
    ];

    const filterConfForMultiSelect = [
      {
        key: 'regionIn',
        displayText: 'Region',
        options: ((regions || []).map((item) => ({
          label: item.region,
          value: item.region,
        }))),
      },
      {
        key: 'productCategories',
        displayText: 'Categories',
        options: ((productCategories || []).map((item) => ({
          label: item.displayName,
          value: (item.id).toString(),
        }))),
      },
    ];

    const filterConf = [
      {
        key: 'hasRegionalPrice',
        displayText: 'Has Regional Price',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'isCurated',
        displayText: 'Curated',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'isPrimary',
        displayText: 'Primary',
        options: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    const seachBars = [
      (
        <Form.Control
          type="text"
          placeholder="Search text"
          name="searchText"
          className="fs-01 rounded-0"
          value={newParams.searchText}
          onChange={this.handleOnChange}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.which === 13) {
              this.applyFilters();
            }
          }}
        />
      ),
      (
        <Form.Control
          type="text"
          placeholder="SKU Codes"
          name="skuCodes"
          className="fs-01 rounded-0"
          value={newParams.skuCodes}
          onChange={this.handleOnChange}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.which === 13) {
              this.applyFilters();
            }
          }}
        />
      ),
      (
        <Form.Control
          type="text"
          placeholder="Product Id's"
          name="productIds"
          className="fs-01 rounded-0"
          value={newParams.productIds}
          onChange={this.handleOnChange}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e.which === 13) {
              this.applyFilters();
            }
          }}
        />
      ),
    ];

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        <CustomModal
          show={showPerformingActionModal}
          title={this.getTitleForAction(action)}
          onHide={() => {
            this.setState({
              showPerformingActionModal: false,
              submitError: false,
              syncType: 'IGNORE',
              mrp: '',
              sellingPrice: '',
              region: '',
              libraryProductId: '',
              reason: '',
              action: '',
              spError: false,
            });
          }}
          size="lg"
          closeButton
          body={(
            <Container>
              {
                selectedRegLibProdToPerformAction
                && (action === 'ARCHIVE_REGIONAL_PRICE')
                && (
                  <>
                    <Row
                      className="pt-3"
                    >
                      <Col xs={8}>
                        <b>Library Product Id:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        {selectedRegLibProdToPerformAction.libraryProductId}
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col xs={8}>
                        <b>Library Product Name:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3 text-truncate"
                      >
                        {selectedRegLibProdToPerformAction.libraryProductName}
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Reason:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        <textarea
                          rows="3"
                          className="form-control"
                          name="reason"
                          value={reason}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>
                  </>
                )
              }
              {
                action === 'ADD_REGIONAL_PRICE' && (
                  <>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Library Product Id:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        <input
                          type="text"
                          className="form-control"
                          name="libraryProductId"
                          value={libraryProductId}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>MRP:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        <input
                          type="number"
                          className="form-control"
                          name="mrp"
                          value={mrp}
                          onChange={this.handleChange}
                        />
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Selling Price:</b>
                      </Col>
                      <Col
                        xs={16}
                        className="px-3"
                      >
                        <input
                          type="number"
                          className="form-control"
                          name="sellingPrice"
                          value={sellingPrice}
                          onChange={this.handleChange}
                        />
                        {
                          spError && (
                            <div className="pt-1 fs-01 text-danger">
                              * Selling Price should be less than MRP.
                            </div>
                          )
                        }
                      </Col>
                    </Row>
                    <Row
                      className="pt-3"
                    >
                      <Col
                        xs={8}
                      >
                        <b>Region:</b>
                      </Col>
                      <Col
                        className="px-3"
                        xs={16}
                      >
                        <select
                          name="region"
                          id="region"
                          className="form-control"
                          value={region}
                          onChange={this.handleChange}
                        >
                          <option value="">--Select Region--</option>
                          {
                            (regions || []).map((item) => (
                              <option value={item.region}>{item.region}</option>
                            ))
                          }
                        </select>
                      </Col>
                    </Row>
                  </>
                )
              }
              <Row
                className="pt-3"
              >
                <Col
                  xs={8}
                >
                  <b>Sync Type:</b>
                </Col>
                <Col
                  className="px-3"
                  xs={16}
                >
                  <select
                    name="syncType"
                    id="syncType"
                    className="form-control"
                    value={syncType}
                    onChange={this.handleChange}
                  >
                    <option value="IGNORE">IGNORE</option>
                    <option value="NOW">NOW</option>
                    <option value="NIGHT">NIGHT</option>
                  </select>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={24}
                  className="text-center pt-5"
                >
                  <Button
                    variant="primary"
                    className="px-3 py-2"
                    disabled={
                      submitting
                      || (
                        action === 'ADD_CURATED_GROUP'
                        && !libraryProductId
                        && !region
                        && !sellingPrice
                        && !mrp
                      )
                    }
                    onClick={this.performAction}
                  >
                    {
                      submitting && (
                        <Spinner
                          variant="light"
                          size="sm"
                          animation="border"
                        />
                      )
                    }
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </Button>
                </Col>
                {submitError && (
                  <Col
                    xs={24}
                    className="text-center text-danger pt-3"
                  >
                    <b>
                      {
                        submitErrorMsg || 'Oops Something went Wrong!!'
                      }
                    </b>
                  </Col>
                )}
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={showLesserSPAlert}
          onHide={() => {
            this.setState({
              showLesserSPAlert: false,
            });
          }}
          autoSize
          body={(
            <div
              className="p-3"
            >
              <b>Are you sure?</b>
              <div
                className="pt-1"
              >
                The selling price you have entered is much lower than MRP.
                Are you sure you want to go ahead?
              </div>
            </div>
          )}
          footer={(
            <>
              <Button
                variant="link"
                className="text-black"
                onClick={() => {
                  this.persistedPatchItem = null;
                  this.setState({
                    showLesserSPAlert: false,
                  });
                }}
              >
                <b>GO BACK</b>
              </Button>
              <Button
                variant="link"
                onClick={() => {
                  this.handleEditProduct(true);
                  this.setState({
                    showLesserSPAlert: false,
                  });
                }}
              >
                <b>UPDATE</b>
              </Button>
            </>
          )}
        />
        <Row
          className="h-100 flex-column"
        >
          <Col
            className="flex-grow-0"
          >
            <Row
              className="align-items-center py-2"
            >
              {
                seachBars.map((item) => (
                  <Col
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          className="rounded-0"
                        >
                          <Svg
                            svg="search"
                            width="1rem"
                            fill={Constant.Color.DARK}
                          />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      {item}
                    </InputGroup>
                  </Col>
                ))
              }
              {
                filterConfForMultiSelect.map((item) => (
                  <Col
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <Select
                      id={item.key}
                      placeholder={item.displayText}
                      onChange={(selectedItems) => {
                        const selectedItemsString = selectedItems.reduce(
                          (acc, selItem, i) => acc.concat(i === 0 ? '' : ',', selItem.value), '',
                        );
                        this.handleDropdownChange({ [item.key]: selectedItemsString });
                      }}
                      selectedVal={newParams[item.key]}
                      options={item.options}
                      isMulti
                      value={newParams[item.key]
                        ? (newParams[item.key].split(','))
                          .map((val) => ({ label: val, value: val }))
                        : []}
                      className="minw-150p"
                    />
                  </Col>
                ))
              }
              {
                filterConf.map((item) => (
                  <Col
                    key={item.key}
                    xs="auto"
                    className="px-2 pt-2"
                  >
                    <CustomDropdown
                      item={item}
                      onChange={this.handleDropdownChange}
                      selectedVal={newParams[item.key]}
                    />
                  </Col>
                ))
              }
            </Row>
            <Row className="py-2 d-flex">
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="primary"
                  className="fs-01"
                >
                  <a
                    // eslint-disable-next-line no-nested-ternary
                    href={`https://catadmin${isProdEnv ? '' : isReleaseEnv ? '-release' : '-staging'}.mpaani.com/dashboard/catalog-management/library-product/create`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    ADD NEW LIBRARY PRODUCT
                  </a>
                </Button>
              </Col>
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="primary"
                  className="fs-01"
                  onClick={() => {
                    history.push({
                      pathname: '/generic-bulk-upload',
                      state: { selectedUploadType: 'BULKADDLIBRARYPRODUCT' },
                    });
                  }}
                >
                  UPDATE LIBRARY PRODUCTS VIA CSV
                </Button>
              </Col>
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="primary"
                  className="fs-01"
                  onClick={() => {
                    history.push({
                      pathname: '/generic-bulk-upload',
                      state: { selectedUploadType: 'PRODUCT_LOCALIZED_PRICE_UPLOADER' },
                    });
                  }}
                >
                  UPDATE REGIONAL PRICES VIA CSV
                </Button>
              </Col>
              <Col
                xs="auto"
                className="px-2 ml-auto"
              >
                <Button
                  variant="success"
                  className="fs-01 font-weight-bold"
                  onClick={this.applyFilters}
                >
                  APPLY FILTERS
                </Button>
              </Col>
              <Col
                xs="auto"
                className="px-2"
              >
                <Button
                  variant="primary"
                  className="fs-01 font-weight-bold"
                  onClick={this.resetfilters}
                >
                  RESET FILTERS
                </Button>
              </Col>
            </Row>
          </Col>
          {
            (loading || error) && (
              <div
                className="pt-3 text-center"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      Something Went Wrong
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
          {
            !!libraryProducts && !loading && !error && (
              <Col
                className="px-0 flex-grow-1 overflow-y"
              >
                <CustomTable
                  headers={headers}
                  content={libraryProducts}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={libraryProductsCount}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

LibraryProducts.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default LibraryProducts;
