import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { CustomModal, CustomTable } from '../../../../component/common';

export default function ConfirmProducts(props) {
  const {
    confirmedProducts, onCancel, onConfirm, onReroute,
    rerouteOrderError, rerouting, customProducts, newProducts,
    orderConfirm, orderConfirmData, orderConfirmClose, onModalClose,
  } = props;

  const [matchedOrderProducts, setMatchedOrderProducts] = useState(null);
  const [matchedCustomProducts, setMatchedCustomProducts] = useState(null);
  let oldDeliveryCharge = 0;
  let newDeliveryCharge = 0;
  let refundAmount = 0;
  let oldBillAmount = 0;
  let newBillAmount = 0;
  let oldMOVAmount = 0;
  let newMOVAmount = 0;
  let oldMOVPenaltyAmount = 0;
  let newMOVPenaltyAmount = 0;
  let oldDiscountAmount = 0;
  let newDiscountAmount = 0;
  if (orderConfirmData) {
    oldDeliveryCharge = orderConfirmData.oldDeliveryCharge;
    newDeliveryCharge = orderConfirmData.newDeliveryCharge;
    refundAmount = orderConfirmData.payableAmountDifference;
    oldBillAmount = orderConfirmData.oldBillAmount;
    newBillAmount = orderConfirmData.newBillAmount;
    oldMOVAmount = orderConfirmData.oldMovAmount;
    newMOVAmount = orderConfirmData.newMovAmount;
    oldMOVPenaltyAmount = orderConfirmData.oldMovPenaltyAmount;
    newMOVPenaltyAmount = orderConfirmData.newMovPenaltyAmount;
    oldDiscountAmount = orderConfirmData.oldDiscountAmount;
    newDiscountAmount = orderConfirmData.newDiscountAmount;
  }

  const updateMatchedProducts = () => {
    setMatchedOrderProducts(
      confirmedProducts.reduce((acc, item) => {
        const s = item.matchingProducts.find((i) => i.isMatched);
        if (s) {
          return ([...acc, ...[s]]);
        }
        return ([...acc]);
      }, []),
    );
  };

  const updateMatchedCustomProducts = () => {
    setMatchedCustomProducts(
      confirmedProducts.reduce((acc, item) => {
        if (item.suggestions) {
          const s = item.suggestions.find((i) => i.isMatched);
          if (s) {
            return [...acc, s];
          }
        }
        return acc;
      }, []),
    );
  };

  useEffect(() => {
    if (!matchedOrderProducts) {
      updateMatchedProducts();
    }
    if (!matchedCustomProducts) {
      updateMatchedCustomProducts();
    }
  }, [confirmedProducts]);

  const headers = [
    {
      key: 'LibraryProductId',
      displayText: 'Id',
      renderer: (data) => {
        return (
          <span>
            {
              // eslint-disable-next-line no-nested-ternary
              data.isCustomProduct
                ? (data.isMatched ? data.LibraryProductId : data.customOrderProductId)
                : data.LibraryProductId
            }

          </span>
        );
      },
    },
    {
      key: 'name',
      displayText: 'Name',
    },
    {
      key: 'imageUrl',
      displayText: 'Image',
      renderer: (data) => (
        <div
          className="product-image-div"
        >
          <img
            src={data.imageUrl}
            alt=""
            className="w-100 h-100"
          />
        </div>
      ),
    },
    {
      key: 'unit',
      displayText: 'Unit',
    },
    {
      key: 'uom',
      displayText: 'UOM',
    },
    {
      key: 'sellingPrice',
      displayText: 'SP.',
    },
    {
      key: 'quantity',
      displayText: 'QTY.',
    },
  ];

  return (
    <Container>
      <CustomModal
        show={orderConfirm}
        title="Order Reroute Confirmation"
        onHide={onModalClose}
        autoSize
        backdrop
        closeButton
        border
        body={(
          <Container className="p-4">
            <Row>
              <table className="table">
                <thead>
                  <tr>
                    <th>Items</th>
                    <th>Current Order Amount</th>
                    <th>New Order Amount</th>
                    <th>Difference</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Delivery Charge</th>
                    <td>{oldDeliveryCharge}</td>
                    <td>{newDeliveryCharge}</td>
                    <td>{newDeliveryCharge - oldDeliveryCharge}</td>
                  </tr>
                  <tr>
                    <th>MOV Value</th>
                    <td>{oldMOVAmount}</td>
                    <td>{newMOVAmount}</td>
                    <td>{newMOVAmount - oldMOVAmount}</td>
                  </tr>
                  <tr>
                    <th>MOV Penalty</th>
                    <td>{oldMOVPenaltyAmount}</td>
                    <td>{newMOVPenaltyAmount}</td>
                    <td>{newMOVPenaltyAmount - oldMOVPenaltyAmount}</td>
                  </tr>
                  {
                    (oldDiscountAmount > 0 || newDiscountAmount > 0) && (
                      <tr>
                        <th>Discount Amount</th>
                        <td>{oldDiscountAmount}</td>
                        <td>{newDiscountAmount}</td>
                        <td>{newDiscountAmount - oldDiscountAmount}</td>
                      </tr>
                    )
                  }
                  <tr>
                    <th>Bill Total</th>
                    <td>{oldBillAmount}</td>
                    <td>{newBillAmount}</td>
                    <td>{newBillAmount - oldBillAmount}</td>
                  </tr>
                </tbody>
              </table>
            </Row>
            <Row className="font-weight-bold justify-content-center">
              {`
              The customer would have to pay Rs ${-(refundAmount)} /-.
              `}
            </Row>
            <Row
              className="pt-4 d-flex align-items-center
              justify-content-center"
            >
              <Button
                variant="primary"
                className="mx-2"
                onClick={() => {
                  onReroute(matchedOrderProducts, customProducts, newProducts);
                }}
                disabled={
                  (matchedOrderProducts && matchedOrderProducts.length === 0
                  && customProducts.length === 0 && newProducts.length === 0)
                  || rerouting
                }
              >
                PLACE ORDER
              </Button>
              <Button
                variant="outline-primary"
                className="mx-2"
                onClick={orderConfirmClose}
              >
                GO BACK
              </Button>
            </Row>
            {
              rerouteOrderError && (
                <Row className="text-danger justify-content-center p-2">
                  Oops! Something went wrong, Please try again.
                </Row>
              )
            }
          </Container>
        )}
      />
      {
      (matchedOrderProducts?.length > 0 || matchedCustomProducts?.length > 0) && (
      <Row>
        <Col
          xs={24}
          className="font-weight-bold"
        >
          Matched order items for order rerouting:
        </Col>
        <Col
          xs={24}
        >
          <CustomTable
            headers={headers}
            content={[...matchedOrderProducts, ...matchedCustomProducts]}
            isPaginated={false}
            totalItems={
                  matchedOrderProducts.length + matchedCustomProducts.length
                }
          />
        </Col>
      </Row>
      )
      }
      <Row>
        <Col
          xs={24}
          className="font-weight-bold"
        >
          Existing order items:
        </Col>
        <Col
          xs={24}
        >
          <CustomTable
            headers={headers}
            content={confirmedProducts.map((item, index) => ({
              ...item.customProduct,
              ...item.orderProduct,

            }))}
            isPaginated={false}
            totalItems={confirmedProducts.length}
            // keyField={keyField}
            keyField="LibraryProductId"
          />
        </Col>
      </Row>
      <Row className="bg-white d-flex justify-content-center py-4">
        <Button
          variant="primary"
          className="mx-2"
          onClick={() => { onConfirm(matchedOrderProducts, customProducts, newProducts); }}
          disabled={
            (matchedOrderProducts && matchedOrderProducts.length === 0
              && customProducts.length === 0 && newProducts.length === 0)
            || rerouting
          }
        >
          REROUTE
        </Button>
        <Button
          variant="outline-primary"
          className="mx-2"
          onClick={onCancel}
        >
          BACK
        </Button>
      </Row>
      {
        rerouteOrderError && (
          <Row className="text-danger justify-content-center pb-4">
            Oops! Something went wrong, Please try again.
          </Row>
        )
      }
    </Container>
  );
}

ConfirmProducts.propTypes = {
  onCancel: PropTypes.func.isRequired,
  confirmedProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // prevCustomProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  newProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onConfirm: PropTypes.func.isRequired,
  onReroute: PropTypes.func.isRequired,
  rerouteOrderError: PropTypes.bool.isRequired,
  rerouting: PropTypes.bool.isRequired,
  orderConfirm: PropTypes.bool,
  orderConfirmData: PropTypes.shape({
    oldDeliveryCharge: PropTypes.number,
    newDeliveryCharge: PropTypes.number,
    refundAmount: PropTypes.number,
    oldBillAmount: PropTypes.number,
    newBillAmount: PropTypes.number,
    oldMovAmount: PropTypes.number,
    newMovAmount: PropTypes.number,
    oldMovPenaltyAmount: PropTypes.number,
    newMovPenaltyAmount: PropTypes.number,
    oldDiscountAmount: PropTypes.number,
    newDiscountAmount: PropTypes.number,
    payableAmountDifference: PropTypes.number,
  }),
  orderConfirmClose: PropTypes.func,
  onModalClose: PropTypes.func,
};

ConfirmProducts.defaultProps = {
  orderConfirm: false,
  orderConfirmData: null,
  orderConfirmClose: () => {},
  onModalClose: () => {},
};
