/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import {
  Container, Row, Col, Spinner, Button, InputGroup, Form,
} from 'react-bootstrap';
import {
  storeReview, reviews, reviewHistory,
} from '../../assets/api/axios';
import {
  CustomTable, Svg, CustomDropdown, DatePicker, CustomModal,
} from '../../component/common';
import { Constant } from '../../utilities';
import { getUserName } from '../../utilities/Storage';
import '../../assets/scss/RetailerManagement/ReviewPanel.scss';
import { stickersInfo } from '../../utilities/Utils';
import Permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l, p, startDate, endDate,
  } = param;
  const {
    storeId = '',
    rating = '',
    status = '',
    customerId = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  startDate = Number(startDate) || '';
  endDate = Number(endDate) || '';
  return ({
    l,
    p,
    storeId,
    status,
    rating,
    customerId,
    startDate,
    endDate,
  });
};

class ReviewPanel extends Component {
  constructor(props) {
    super(props);
    const { history } = props;
    const param = getQueryParams(history.location.search);
    const { userPermission } = this.props;
    this.canIEdit = userPermission.includes(Permission.REVIEW_PANEL_WRITE);
    this.state = {
      rowsPerPage: param.l,
      page: param.p,
      reviewList: [],
      reviewCount: 0,
      hasNext: false,
      hasPrevious: false,
      loading: true,
      error: false,
      storeId: param.storeId,
      customerId: param.customerId,
      param,
      selectedReview: null,
      showReviewStatus: '',
      reviewsHistory: [],
      selectedReviewType: null,
      selectedReviewIds: [],
      selectedStickerInfo: null,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { history } = this.props;
    const { param } = this.state;
    const newParam = getQueryParams(history.location.search);
    if (
      Object.keys(newParam).find((key) => (
        (param[key] !== newParam[key])
        && (param[key] === prevState.param[key])
      ))
    ) {
      this.handleLoad({ ...newParam });
    }
  }

  handleLoad = (data = {}) => {
    const { history } = this.props;
    const { pathname } = history.location;
    const { param } = this.state;
    const newParam = { ...param, ...data };
    const {
      l, p, storeId, customerId, status, rating, startDate, endDate,
    } = newParam;
    const offset = (p - 1) * l;

    reviews(
      'GET', `${offset}`, l, storeId, customerId,
      status, rating, startDate, endDate, this.source.token,
    ).then((res) => {
      this.setState({
        loading: false,
        reviewList: res.data.results,
        reviewCount: res.data.count,
        hasNext: res.data.hasNext,
        hasPrevious: res.data.hasPrevious,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch(() => {
      this.setState({
        loading: false,
        error: true,
      });
    });
    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDateChange = (startDate, endDate) => {
    const { param } = this.state;
    if (startDate !== param.startDate
      || endDate !== param.endDate) {
      this.handleRequestProcessing({
        startDate,
        endDate,
        p: 1,
      });
    }
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  onSubmitStoreId = () => {
    const {
      storeId, param,
    } = this.state;
    if (storeId !== param.q) {
      this.handleRequestProcessing({ storeId, p: 1 });
    }
  }

  onSubmitCustomerId = () => {
    const {
      customerId, param,
    } = this.state;
    if (customerId !== param.q) {
      this.handleRequestProcessing({ customerId, p: 1 });
    }
  }

  resetFilters = () => {
    const { param } = this.state;
    const { l } = param;
    if (
      Object.keys(param).find((item) => (
        (item !== 'l') && (item !== 'p')
      && param[item]))) {
      this.handleRequestProcessing({
        ...getQueryParams({ l, p: 1 }),
      });
    }
  }

  handleShowStatus = (data) => {
    this.setState({
      selectedReview: data,
      showReviewStatus: 'loading',
    }, () => {
      const { selectedReview } = this.state;
      reviewHistory(
        'get',
        selectedReview.id,
      ).then((res) => {
        this.setState({
          reviewsHistory: res.data.results,
          showReviewStatus: 'success',
        });
      }).catch(() => {
        this.setState({
          showReviewStatus: 'error',
        });
      });
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  updatePatchStatus = (reviewType) => {
    const { reviewList, selectedReviewIds } = this.state;
    const newReviewList = reviewList.map((item) => {
      if (selectedReviewIds.includes(item.id)) {
        return ({
          ...item,
          status: reviewType,
        });
      }
      return item;
    });
    this.setState({ reviewList: newReviewList });
  }

  handlePatchStatus = (data) => {
    const { selectedReviewIds } = this.state;
    const patchBody = {
      updatedStatus: data.reviewType,
      user: getUserName(),
      reviewIds: selectedReviewIds,
    };
    storeReview(
      'patch',
      data.storeId,
      patchBody,
    ).then(() => {
      this.updatePatchStatus(data.reviewType);
      this.setState({
        selectedReviewType: null,
        selectedReviewIds: [],
      });
    }).catch(() => {});
  }

  toggleSelectReviewId = (event, targetReviewId) => {
    const { selectedReviewIds } = this.state;
    if (event.target.checked) {
      selectedReviewIds.push(targetReviewId);
    } else {
      const reviewIndex = selectedReviewIds.findIndex(
        (reviewId) => reviewId === targetReviewId,
      );
      selectedReviewIds.splice(reviewIndex, 1);
    }
    this.setState({
      selectedReviewIds,
    });
  }

  toggleSelectAll= (event) => {
    const { reviewList } = this.state;
    if (event.target.checked) {
      const reviewIds = reviewList.map((review) => review.id);
      this.setState({
        selectedReviewIds: reviewIds,
      });
    } else {
      this.setState({
        selectedReviewIds: [],
      });
    }
  }

  render() {
    const {
      loading, error, param, reviewList,
      page, rowsPerPage, storeId, customerId,
      reviewCount, hasNext, hasPrevious, selectedReview,
      reviewsHistory, showReviewStatus, selectedReviewType,
      selectedReviewIds, selectedStickerInfo,
    } = this.state;

    const reviewHistoryHeaders = [
      {
        key: 'modifiedBy',
        displayText: 'Modified By',
      },
      {
        key: 'status',
        displayText: 'Status',
      },
      {
        key: 'content',
        displayText: 'Review',
      },
    ];

    const confirmationBody = (
      <Container
        fluid
      >
        {selectedReviewType && (
        <Row>
          <Col
            xs={24}
            className="text-center pt-3"
          >
            <span
              className="fs-3"
            >
              Are you sure you want to&nbsp;
              <b>
                {selectedReviewType.reviewType === 'APPROVED' ? ('APPROVE') : ('DISAPPROVE')}
              </b>
              &nbsp;
              {selectedReviewType.multiple ? ' these reviews ? ' : ' this review ? '}
            </span>
          </Col>
          <Col
            xs={24}
            className="text-center py-3"
          >
            <Button
              variant="outline-primary"
              className="py-1 px-3"
              onClick={() => {
                this.setState({
                  selectedReviewType: null,
                  selectedReviewIds: [],
                });
              }}
            >
              Not Now
            </Button>
            <span
              className="pl-3"
            >
              <Button
                variant={selectedReviewType.reviewType === 'APPROVED' ? 'success' : 'danger'}
                className="py-1 px-3"
                onClick={
                  () => this.handlePatchStatus(selectedReviewType)
                }
              >
                Submit
              </Button>
            </span>
          </Col>
        </Row>
        )}
      </Container>
    );

    const reviewHistoryBody = (
      <Container
        fluid
      >
        <Row>
          {showReviewStatus === 'loading' ? (
            <Col
              xs={24}
              className="text-center pt-3"
            >
              <Spinner
                animation="border"
                variant="primary"
              />
            </Col>
          ) : showReviewStatus === 'error' ? (
            <Col
              xs={24}
              className="text-center pt-3"
            >
              Oops something Went Wrong!!
            </Col>
          ) : (
            <Col
              xs={24}
              className="px-0"
            >
              <CustomTable
                headers={reviewHistoryHeaders}
                content={reviewsHistory}
                keyField="id"
                isPaginated={false}
                totalItems={reviewsHistory.length}
              />
            </Col>
          )}
        </Row>
      </Container>
    );

    const headers = [
      ...this.canIEdit
        ? [{
          key: 'selectAll',
          displayText: (
            <input
              type="checkbox"
              checked={selectedReviewIds.length === reviewList.length}
              onChange={(event) => this.toggleSelectAll(event)}
            />
          ),
          renderer: ((data) => (
            <input
              type="checkbox"
              checked={selectedReviewIds.includes(data.id)}
              onChange={(event) => this.toggleSelectReviewId(event, data.id)}
            />
          )),
        }] : [],
      {
        key: 'id',
        displayText: 'Review Id',
        renderer: (data) => (
          <Button
            variant="link"
            className="fs-01"
            onClick={() => this.handleShowStatus(data)}
          >
            {data.id}
          </Button>
        ),
      },
      {
        key: 'customer_uuid',
        displayText: 'Customer Id',
      },
      {
        key: 'storeId',
        displayText: 'Store Id',
      },
      {
        key: 'storeName',
        displayText: 'Shop Name',
      },
      {
        key: 'rating',
        displayText: 'Rating',
      },
      {
        key: 'content',
        displayText: 'Review',
        renderer: (data) => (
          <div
            className="review-width"
          >
            {data.content ? (
              data.content
            ) : (
              'NA'
            )}
          </div>
        ),
      },
      {
        key: 'viewStickers',
        displayText: 'Stickers',
        renderer: (data) => (
          <div>
            {
              data.sticker.length > 0 ? (
                <Button
                  variant="link"
                  className="p-0 fs-01"
                  onClick={() => {
                    this.setState({
                      selectedStickerInfo: data,
                    });
                  }}
                >
                  VIEW STICKERS
                </Button>
              ) : (
                <i>
                  --No Stickers--
                </i>
              )
            }
          </div>
        ),
      },
      {
        key: 'created_at',
        displayText: 'Date of Review',
        renderer: (data) => (
          <>{new Date(data.created_at).toLocaleDateString()}</>
        ),
      },
      {
        key: 'status',
        displayText: 'Status',
        renderer: (data) => (
          <select
            className="form-control fs-01"
            value={data.status}
            onChange={(event) => {
              this.setState({
                selectedReviewIds: [data.id],
                selectedReviewType: {
                  reviewType: event.target.value,
                  ...data,
                },
              });
            }}
            disabled={!this.canIEdit}
          >
            {data.status === 'PENDING' && (
              <option value="PENDING">PENDING</option>
            )}
            <option value="APPROVED">APPROVED</option>
            <option value="DISAPPROVED">DISAPPROVED</option>
          </select>
        ),
      },
    ];

    const filterConf = [
      {
        key: 'status',
        displayText: 'Status',
        options: [
          {
            label: 'PENDING',
            value: 'PENDING',
          },
          {
            label: 'APPROVED',
            value: 'APPROVED',
          },
          {
            label: 'DISAPPROVED',
            value: 'DISAPPROVED',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
      {
        key: 'rating',
        displayText: 'Rating',
        options: [
          {
            label: '1',
            value: '1',
          },
          {
            label: '2',
            value: '2',
          },
          {
            label: '3',
            value: '3',
          },
          {
            label: '4',
            value: '4',
          },
          {
            label: '5',
            value: '5',
          },
          {
            label: 'None',
            value: '',
          },
        ],
      },
    ];

    if (loading || error) {
      return (
        <div
          className="pt-3 text-center"
        >
          {loading ? (
            <Spinner
              animation="border"
              variant="primary"
            />
          ) : (
            <>
              <span
                className="text-danger"
              >
              Something Went Wrong
              </span>
              <div>
                <Button
                  variant="primary"
                  onClick={() => this.retry()}
                >
                Retry
                </Button>
              </div>
            </>
          )}
        </div>
      );
    }

    return (
      <Container
        fluid
        className="h-100 bg-white"
      >
        {
          !!selectedStickerInfo && (
            <CustomModal
              show={!!selectedStickerInfo}
              body={(
                <div>
                  {
                    selectedStickerInfo.sticker.map((sticker) => (
                      <div
                        key={sticker}
                        className={`d-flex align-items-center p-3
                         ${stickersInfo[sticker].isNegativeFeedback ? 'border border-danger' : 'border'}`}
                      >
                        <img
                          src={stickersInfo[sticker].url}
                          alt=""
                          className="sticker-image"
                        />
                        <div
                          className={`pl-3 ${stickersInfo[sticker].isNegativeFeedback ? 'text-danger' : ''}`}
                        >
                          {stickersInfo[sticker].text}
                        </div>
                      </div>
                    ))
                  }
                </div>
              )}
              title={(
                <span
                  className="fs-02"
                >
                  Review Stickers
                </span>
              )}
              onHide={() => {
                this.setState({
                  selectedStickerInfo: null,
                });
              }}
              closeButton
            />
          )
        }
        <CustomModal
          show={!!selectedReviewType}
          body={confirmationBody}
          onHide={() => {
            this.setState({
              selectedReviewType: null,
            });
          }}
        />
        <CustomModal
          show={!!selectedReview}
          body={reviewHistoryBody}
          title={(
            <span
              className="fs-02"
            >
              Review History
            </span>
          )}
          onHide={() => {
            this.setState({
              selectedReview: null,
              showReviewStatus: '',
            });
          }}
          closeButton
        />
        <Row>
          <Col
            xs={24}
            sm="auto"
            className="px-2 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Store Id"
                name="storeId"
                className="fs-01 rounded-0"
                value={storeId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitStoreId();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col
            xs={24}
            sm="auto"
            className="px-2 py-2"
          >
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text
                  className="rounded-0"
                >
                  <Svg
                    svg="search"
                    width="1rem"
                    fill={Constant.Color.DARK}
                  />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Customer Id"
                className="fs-01 rounded-0"
                name="customerId"
                value={customerId}
                onChange={this.handleOnChange}
                autoComplete="off"
                onKeyPress={(e) => {
                  if (e.which === 13) {
                    this.onSubmitCustomerId();
                  }
                }}
              />
            </InputGroup>
          </Col>
          {filterConf.map((item) => (
            <Col
              key={item.key}
              xs="auto"
              className="px-2 py-2"
            >
              <CustomDropdown
                item={item}
                onChange={this.handleDropdownChange}
                selectedVal={param[item.key]}
              />
            </Col>
          ))}
          <Col
            xs="auto"
            className="px-2 py-2"
          >
            <DatePicker
              isDateRange
              onApply={(dateRange) => {
                this.handleDateChange(
                  new Date(dateRange.startDate).getTime(),
                  new Date(dateRange.endDate).getTime(),
                );
              }}
              startDate={param.startDate}
              endDate={param.endDate}
              onClear={() => {
                this.handleRequestProcessing({
                  startDate: '', endDate: '',
                });
              }}
            />
          </Col>
          <Col
            xs="auto"
            className="py-2 ml-auto px-3"
          >
            <Button
              variant="outline-primary"
              className="fs-01"
              onClick={this.resetFilters}
            >
              Reset
            </Button>
          </Col>
        </Row>
        {
          selectedReviewIds.length > 0 && (
            <Row
              className="pb-2 align-items-center fs-01"
            >
              <Col
                xs="auto"
              >
                Action &nbsp;:
              </Col>
              <Col
                xs="auto"
              >
                <select
                  className="form-control fs-01 rounded-0"
                  onChange={(event) => {
                    this.setState({
                      selectedReviewType: {
                        reviewType: event.target.value,
                        multiple: true,
                      },
                    });
                  }}
                >
                  <option value="">Select Action</option>
                  <option value="APPROVED">APPROVED</option>
                  <option value="DISAPPROVED">DISAPPROVED</option>
                </select>
              </Col>
            </Row>
          )
        }
        <Row>
          <Col
            xs={24}
            className="px-0"
          >
            <CustomTable
              headers={headers}
              content={reviewList}
              keyField="id"
              l={param.l}
              p={param.p}
              rowsPerPage={rowsPerPage}
              page={page}
              totalItems={reviewCount}
              hasPrev={hasPrevious}
              hasNext={hasNext}
              onNext={this.onNext}
              onPrev={this.onPrev}
              onSubmitPage={this.onSubmitPage}
              onSubmitRowsPerPage={this.onSubmitRowsPerPage}
              updateRowsPageInput={this.handleRowsPageInput}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

ReviewPanel.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};


export default ReviewPanel;
