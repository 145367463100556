import React, { Component } from 'react';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import Axios from 'axios';
import { retailerWalletTransaction, retailerWalletInfo } from '../../assets/api/axios';
import { CustomDropdown, CustomModal, CustomTable } from '../../component/common';
import permission from '../../access&permissions/permission';

const getQueryParams = (data) => {
  const param = QueryString.parse(data);
  let {
    l,
    p,
  } = param;
  const {
    type = '',
    year = `${new Date().getFullYear()}`,
    month = '',
  } = param;
  l = Number(l);
  p = Number(p);
  l = (l && l > 0) ? l : 10;
  p = (p && p > 0) ? p : 1;
  return ({
    ...param,
    l,
    p,
    type,
    year,
    month,
  });
};

class RetailerWallet extends Component {
  constructor(props) {
    super(props);
    const param = getQueryParams(props.history.location.search);
    const { userPermission } = this.props;
    this.canEdit = userPermission.includes(permission.BRAND_SAMPLING_CAMPAIGN_WRITE);
    this.state = {
      hasNext: false,
      hasPrevious: false,
      rowsPerPage: param.l,
      page: param.p,
      loading: true,
      error: false,
      errorMsg: '',
      transactions: null,
      walletInfo: null,
      param,
      newParam: param,
      showDebitEvents: null,
    };
    this.source = Axios.CancelToken.source();
  }

  componentDidMount = () => {
    this.handleLoad();
    this.handleLoadWalletInfo();
  }

  handleRequestProcessing = (data = {}) => {
    const { loading } = this.state;
    if (loading) {
      this.source.cancel();
      this.source = Axios.CancelToken.source();
    }
    this.setState({
      error: false,
      loading: true,
    }, () => {
      this.handleLoad(data);
    });
  }

  handleDropdownChange = (data) => {
    this.handleRequestProcessing({
      ...data, p: 1,
    });
  }

  handleRowsPageInput = (value, field) => {
    this.setState({
      [field]: value,
    });
  }

  onNext = () => {
    const { param } = this.state;
    if (param.p + 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p + 1 });
    }
  }

  onPrev = () => {
    const { param } = this.state;
    if (param.p - 1 !== param.p) {
      this.handleRequestProcessing({ p: param.p - 1 });
    }
  }

  onSubmitRowsPerPage = () => {
    const {
      rowsPerPage, param,
    } = this.state;
    if (rowsPerPage !== param.l) {
      this.handleRequestProcessing({ l: rowsPerPage });
    }
  }

  onSubmitPage = () => {
    const {
      page, param,
    } = this.state;
    if (page !== param.p) {
      this.handleRequestProcessing({ p: page });
    }
  }

  handleLoadWalletInfo = () => {
    const { match } = this.props;
    const { code } = match.params;
    retailerWalletInfo(
      'GET',
      code,
      null,
      {},
    ).then((res) => {
      this.setState({
        walletInfo: res.data.data,
      });
    }).catch(() => {
      //
    });
  }

  handleLoad = (data = {}) => {
    const { history, match } = this.props;
    const { code } = match.params;
    const { pathname } = history.location;
    const {
      param,
    } = this.state;
    const newParam = {
      ...param,
      ...data,
    };
    const {
      l,
      p,
      type,
      month,
      year,
    } = newParam;
    const offset = (p);
    retailerWalletTransaction(
      'GET',
      code,
      null,
      {
        page: `${offset}`,
        limit: l,
        type,
        month,
        year,
      },
    ).then((res) => {
      this.setState({
        transactions: res.data,
        loading: false,
        hasNext: !!res.data.next,
        hasPrevious: !!res.data.prev,
        rowsPerPage: l,
        page: p,
        param: { ...newParam },
      }, () => {
        if (Object.keys(newParam).find((key) => (newParam[key] !== param[key]))) {
          Object.keys(newParam).forEach((item) => {
            if (!newParam[item]) {
              delete newParam[item];
            }
          });
          history.push({
            path: pathname,
            search: QueryString.stringify(newParam),
          });
        }
      });
    }).catch((err) => {
      let errorMsg = '';
      if (
        err
        && err.response
        && err.response.data
        && err.response.data.errors
        && err.response.data.errors[0]
      ) {
        errorMsg = err.response.data.errors[0].message;
      }
      this.setState({
        error: true,
        loading: false,
        errorMsg,
      });
    });

    this.retry = () => {
      this.setState({
        loading: true,
        error: false,
        errorMsg: '',
      }, () => {
        this.handleLoad({ ...newParam });
      });
    };
  }

  applyFilters = () => {
    const { newParam } = this.state;
    this.handleRequestProcessing({
      ...newParam,
      p: 1,
    });
  }

  updateEventStatus = (e, rowData, index) => {
    const { match } = this.props;
    const { code } = match.params;
    const status = e.target.value;
    retailerWalletTransaction(
      'PATCH',
      code,
      {
        transactionId: rowData.id,
        status,
      },
      {},
    ).then(() => {
      const { transactions } = this.state;
      if (
        index >= 0
        && transactions.data[index].id === rowData.id
      ) {
        const updatedTransaction = transactions.data;
        updatedTransaction[index] = {
          ...rowData,
          status,
        };
        this.setState({
          transactions: {
            ...transactions,
            data: updatedTransaction,
          },
        });
      }
    }).catch(() => {
      //
    });
  }

  render() {
    const {
      param, loading, error, transactions,
      rowsPerPage, page, hasNext, hasPrevious,
      errorMsg, walletInfo, showDebitEvents,
    } = this.state;

    // const creditEvents = walletInfo ? walletInfo.creditEvents : [];
    const debitEvents = walletInfo ? walletInfo.debitEvents : [];
    const pointBalance = walletInfo ? walletInfo.balance : 0;

    const headers = [
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'storeCode',
        displayText: 'Store Code',
      },
      {
        key: 'amount',
        displayText: 'Coin',
        renderer: (data) => (
          <span
            className={data.type === 'Credit' ? 'text-green' : 'text-danger'}
          >
            {data.amount}
          </span>
        ),
      },
      {
        key: 'name',
        displayText: 'Event Name',
      },
      {
        key: 'eventCode',
        displayText: 'Event Code',
      },
      {
        key: 'status',
        displayText: 'Status',
        renderer: (rowData, index) => (
          <div>
            {
              rowData.type === 'Credit' ? (
                'CREDITED'
              ) : (
                <select
                  onChange={(e) => { this.updateEventStatus(e, rowData, index); }}
                  value={rowData.status}
                  className="p-1"
                  disabled={rowData.status !== 'Pending'}
                >
                  <option value="Pending">PENDING</option>
                  <option value="Cancelled">CANCELLED</option>
                  <option value="Delivered">DELIVERED</option>
                </select>
              )
            }
          </div>
        ),
      },
    ];

    const filterConf = [{
      key: 'type',
      displayText: 'Credit Type',
      options: [
        {
          label: 'Credit',
          value: 'Credit',
        },
        {
          label: 'Debit',
          value: 'Debit',
        },
        {
          label: 'None',
          value: '',
        },
      ],
    },
    {
      key: 'month',
      displayText: 'Month',
      options: [
        {
          label: 'JAN',
          value: '1',
        },
        {
          label: 'FEB',
          value: '2',
        },
        {
          label: 'MAR',
          value: '3',
        },
        {
          label: 'APR',
          value: '4',
        },
        {
          label: 'MAY',
          value: '5',
        },
        {
          label: 'JUN',
          value: '6',
        },
        {
          label: 'JUL',
          value: '7',
        },
        {
          label: 'AUG',
          value: '8',
        },
        {
          label: 'SEP',
          value: '9',
        },
        {
          label: 'OCT',
          value: '10',
        },
        {
          label: 'NOV',
          value: '11',
        },
        {
          label: 'DEC',
          value: '12',
        },
        {
          label: 'SEE ALL',
          value: '',
        },
      ],
    },
    {
      key: 'year',
      displayText: 'Year',
      options: [
        {
          label: '2023',
          value: '2023',
        },
        {
          label: '2024',
          value: '2024',
        },
      ],
    }];

    return (
      <Container
        fluid
        className="h-100 bg-white table-list"
      >
        <CustomModal
          show={!!showDebitEvents}
          title="Available Items"
          closeButton
          onHide={() => {
            this.setState({
              showDebitEvents: null,
            });
          }}
          body={(
            <Container>
              {
                debitEvents.length === 0 && (
                  <Row
                    className="border border-radius-8 p-1 my-2 mx-2"
                  >
                    <Col
                      className="font-weight-bold text-center text-medium"
                    >
                      No Rewards Available!
                    </Col>
                  </Row>
                )
              }
              {
                debitEvents.map((item) => (
                  <Row
                    key={item.code}
                    className="border border-radius-8 p-1 my-2 mx-2 align-items-center"
                  >
                    <Col
                      xs={8}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        className="mw-100 mh-100"
                      />
                    </Col>
                    <Col
                      xs={16}
                    >
                      <div
                        className="font-weight-bold fs-3"
                      >
                        {item.name}
                      </div>
                      <div
                        className="text-medium fs-1"
                      >
                        {item.description}
                      </div>
                      <div
                        className="text-success fs-1"
                      >
                        Redeem Coin:&nbsp;
                        {item.coin}
                      </div>
                      <div
                        className="text-info fs-1"
                      >
                        Redeem Count:&nbsp;
                        {item.redeemedCount}
                      </div>
                      <div
                        className="text-info fs-1"
                      >
                        Redeem Limit:&nbsp;
                        {item.redeemLimit}
                      </div>
                    </Col>
                  </Row>
                ))
              }
            </Container>
          )}
        />
        <Row
          className="py-2"
        >
          <Col
            xs={24}
          >
            <Row
              className="pb-3"
            >
              {filterConf.map((item) => (
                <Col
                  key={item.key}
                  xs="auto"
                  className="px-2 py-1"
                >
                  <CustomDropdown
                    item={item}
                    onChange={this.handleDropdownChange}
                    selectedVal={param[item.key]}
                  />
                </Col>
              ))}
              <Col
                xs="auto"
                className="border fs-1 font-weight-black border-radius-4 text-success d-flex align-items-center"
              >
                Total Coins:
                &nbsp;
                {pointBalance}
              </Col>

              <Col
                xs="auto"
                className="border-radius-4 fs-1 d-flex align-items-center"
              >
                <Button
                  onClick={() => {
                    this.setState({
                      showDebitEvents: debitEvents,
                    });
                  }}
                >
                  Available Items
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          className="justify-content-center"
        >
          {
            (loading || error) && (
              <div
                className="pt-3"
              >
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="primary"
                  />
                ) : (
                  <>
                    <span
                      className="text-danger"
                    >
                      {errorMsg || 'Something Went Wrong...!'}
                    </span>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => this.retry()}
                      >
                        Retry
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )
          }
        </Row>
        <Row className="bg-white">
          {
            !!transactions && !loading && !error && (
              <Col
                xs={24}
                className="px-0 pt-2 table-section"
              >
                <CustomTable
                  headers={headers}
                  content={transactions.data}
                  keyField="id"
                  l={param.l}
                  p={param.p}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  totalItems={transactions.count}
                  hasPrev={hasPrevious}
                  hasNext={hasNext}
                  onNext={this.onNext}
                  onPrev={this.onPrev}
                  onSubmitPage={this.onSubmitPage}
                  onSubmitRowsPerPage={this.onSubmitRowsPerPage}
                  updateRowsPageInput={this.handleRowsPageInput}
                  isPaginated={false}
                />
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }
}

RetailerWallet.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      code: PropTypes.string,
    }),
  }).isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default RetailerWallet;
