import Axios from 'axios';
import * as URL from './url';
import { Storage } from '../../utilities';

export const getHeaders = (otherHeaders) => {
  const headers = {
    Authorization: `Token ${Storage.getToken()}`,
    'Client-Code': 'Admin',
    'Client-User': Storage.getUserName(),
    userId: Storage.getUserName(),
    'Session-Token': Storage.getUserSession(),
    'x-access-token': 'c8646893d8bd640e73e57727d4fb4335',
    ...otherHeaders,
  };
  return headers;
};

export const getPayload = (data) => {
  const payload = data;
  Object.keys(payload).forEach((item) => {
    if (!data[item]) {
      delete payload[item];
    }
  });
  return payload;
};

const getUidHeader = (customerId) => (customerId ? { 'Customer-Id': customerId } : {});

export const getParams = (data) => {
  const params = data;
  Object.keys(params).forEach((item) => {
    if (!data[item]) {
      delete params[item];
    }
  });
  return params;
};

export const banners = (method, data, params = {}, uid) => (
  Axios({
    method,
    url: URL.banners(uid),
    headers: getHeaders(),
    params: getParams(params),
    data,
  }));

export const customerAddress = (method, data, addressId, customerId, isDefault) => (
  Axios({
    method,
    url: URL.customerAddress(addressId),
    headers: getHeaders(getUidHeader(customerId)),
    data,
    params: getParams({ customerId, default: isDefault }),
  }));

export const productSearch = (method, data, customerId, location, storeId, cartId, searchText,
  offset, limit, cancelToken) => (
  Axios({
    method,
    url: URL.onlineProduct(),
    headers: getHeaders(getUidHeader(customerId)),
    data,
    cancelToken,
    params: getParams({
      location, cartId, searchText, storeId, offset, limit,
    }),
  }));

export const cart = (method, data, cartId, customerId, aggregateBy, param = {}) => (
  Axios({
    method,
    url: URL.cart(cartId),
    headers: getHeaders(getUidHeader(customerId)),
    data,
    params: getParams({
      aggregateBy,
      ...param,
    }),
  })
);

export const order = (method, data, customerId = '', params = {}, orderId = '') => (
  Axios({
    method,
    url: URL.order(orderId),
    headers: getHeaders(getUidHeader(customerId)),
    data,
    params: getParams(params),
  }));

export const customer = (method, data, searchText, cancelToken) => (
  Axios({
    method,
    url: URL.customer(),
    headers: getHeaders(),
    data,
    cancelToken,
    params: getParams({ searchText }),
  }));

export const customerOperation = (method, params = {}, data, cancelToken) => (
  Axios({
    method,
    url: URL.customerOperation(),
    headers: getHeaders(),
    data,
    params: getParams(params),
    cancelToken,
  })
);

export const store = (method, data, customerId, location) => (
  Axios({
    method,
    url: URL.onlineStore(),
    headers: getHeaders(getUidHeader(customerId)),
    data,
    params: getParams({ location }),
  }));

export const storeWithMatrics = (method, data, customerId, params = {}) => (
  Axios({
    method,
    url: URL.onlineOrderStoreWithMatrix(),
    headers: getHeaders(getUidHeader(customerId)),
    data,
    params: getParams(params),
  }));

export const exclusiveOffer = (method, data) => (
  Axios({
    method,
    url: URL.exclusiveOffer(),
    headers: getHeaders(),
    data,
  }));

export const settlement = (
  method, params = {}, data = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.settlement(),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  }));

export const storeSettlement = (
  method, params = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.storeSettlement(),
    headers: getHeaders(),
    params: getParams(params),
    cancelToken,
  }));

export const smsSent = (
  method, params = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.smsSent(),
    params: getParams(params),
    cancelToken,
  })
);

export const reconcile = (
  method, data, reconcileId, cancelToken, params = {},
) => (
  Axios({
    method,
    url: URL.reconcile(reconcileId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  }));

export const shopAllocation = (
  method, data, storeCodes, searchText,
  teamids, offset, limit, latest,
) => (
  Axios({
    method,
    url: URL.shopAllocation(),
    headers: getHeaders(),
    data,
    params: getParams({
      searchText, storeCodes, teamids, offset, limit, latest,
    }),
  }));

export const ramStore = (
  method, param = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.ramStore(),
    headers: getHeaders(),
    cancelToken,
    params: getParams(param),
  }));

export const genericBulkUpload = (method, data, param = {}, cancelToken) => (
  Axios({
    method,
    url: URL.bulkupload(),
    headers: getHeaders(),
    data,
    params: getParams(param),
    cancelToken,
  }));

export const storeCategory = (method) => (
  Axios({
    method,
    url: URL.storeType(),
    headers: getHeaders(),
  }));

export const storecomments = (method, storeCode, startDate, endDate, data) => (
  Axios({
    method,
    url: URL.storecomments(storeCode),
    headers: getHeaders(),
    data,
    params: getParams({
      startDate,
      endDate,
    }),
  })
);

export const orderComments = (method, orderId, params = {}, data) => (
  Axios({
    method,
    url: URL.ordercomments(orderId),
    headers: getHeaders(),
    params: getParams(params),
    data,
  })
);

export const storeDetails = (method, code, data, cancelToken) => (
  Axios({
    method,
    url: URL.ramStore(code),
    headers: getHeaders(),
    data,
    cancelToken,
  }));

export const storeJourney = (method, code, cancelToken) => (
  Axios({
    method,
    url: URL.storeJourney(code),
    headers: getHeaders(),
    cancelToken,
  }));

export const storeTrainingDate = (method, data, cancelToken) => (
  Axios({
    method,
    data,
    url: URL.storeTrainingDate(),
    headers: getHeaders(),
    cancelToken,
  }));

export const ramStoreContext = (method, storeCode, param = {}, cancelToken) => (
  Axios({
    method,
    url: URL.ramStoreContext(storeCode),
    headers: getHeaders(),
    params: getParams(param),
    cancelToken,
  }));

export const exotelCallInit = (method, data) => (
  Axios({
    method,
    url: URL.exotelCall(),
    headers: getHeaders(),
    data,
  })
);

export const exotelCallDetails = (method, data, id, param = {}) => (
  Axios({
    method,
    url: URL.exotelDetails(id),
    headers: getHeaders(),
    data,
    params: getParams(param),
  }));

export const stores = (method, limitedFields) => (
  Axios({
    method,
    url: URL.store(),
    headers: getHeaders(),
    params: getParams({ limitedFields }),
  }));

export const storeProfile = (method, storeCode, data, params = {}) => (
  Axios({
    method,
    url: URL.store(storeCode),
    params: getParams(params),
    data,
    headers: getHeaders(),
  }));

export const userLogin = (method, data = {}, params = {}) => (
  Axios({
    method,
    url: URL.userService(),
    data,
    params: getParams(params),
    headers: getHeaders(),
  }));

export const storeReview = (method, storeCode, data) => (
  Axios({
    method,
    url: URL.storeReview(storeCode),
    headers: { 'Cilent-Code': 'Admin' },
    data,
  })
);

export const orders = (method, orderId, params = {}, cancelToken, data = {}) => (
  Axios({
    method,
    url: URL.order(orderId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const storeProduct = (method, data, productId, params = {}, cancelToken) => (
  Axios({
    method,
    url: URL.storeProduct(productId),
    headers: getHeaders(),
    data,
    params: getParams(params),
    cancelToken,
  }));

export const storeProductBulkEdit = (method, data) => (
  Axios({
    method,
    url: URL.storeProductBulkEdit(),
    headers: getHeaders(),
    data,
  }));

export const productGroups = (method, data) => (
  Axios({
    method,
    url: URL.productGroups(),
    headers: getHeaders(),
    data,
  })
);

export const productGroupProducts = (method, data, params = {}) => (
  Axios({
    method,
    url: URL.productGroupProducts(),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const productGroupBulkAdd = (method, data, adminProcessedId, params = {}) => (
  Axios({
    method,
    url: URL.productGroupBulkAdd(adminProcessedId),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const libraryProduct = (method, data, productId, params = {}, cancelToken) => (
  Axios({
    method,
    url: URL.libraryProduct(productId),
    headers: getHeaders(),
    data,
    params: getParams(params),
    cancelToken,
  })
);

export const retailerRequest = (method, data, cancelToken) => (
  Axios({
    method,
    url: URL.retailerRequest(),
    headers: getHeaders(),
    data,
    cancelToken,
  })
);

export const allocationTeam = (method) => (
  Axios({
    method,
    url: URL.allocationTeam(),
    headers: getHeaders(),
  })
);

export const storeTagOptions = (method) => (
  Axios({
    method,
    url: URL.storeTagOption(),
    headers: getHeaders(),
  })
);

export const storeLifeCycleState = (method) => (
  Axios({
    method,
    url: URL.storeLifeCycleState(),
    headers: getHeaders(),
  })
);

export const storeLifeCycle = (method, data) => (
  Axios({
    method,
    url: URL.storeLifeCycle(),
    headers: getHeaders(),
    data,
  })
);

export const storeLifeCycleHistory = (method, storeId) => (
  Axios({
    method,
    url: URL.storeLifeCycleHistory(storeId),
    headers: getHeaders(),
  })
);

export const misReport = (method) => (
  Axios({
    method,
    url: URL.misReport(),
    headers: getHeaders(),
  })
);

export const ramStoresWithRating = (
  method, offset, limit, searchText, sortBy, cancelToken,
) => (
  Axios({
    method,
    url: URL.ramStoresWithRating(),
    headers: getHeaders(),
    params: getParams({
      offset,
      limit,
      searchText,
      sortBy,
    }),
    cancelToken,
  }));

export const reviews = (
  method, offset, limit, storeId,
  customerId, status, rating, startDate, endDate, cancelToken,
) => (
  Axios({
    method,
    url: URL.reviews(),
    headers: getHeaders(),
    params: getParams({
      offset,
      limit,
      storeId,
      customerId,
      status,
      rating,
      startDate,
      endDate,
    }),
    cancelToken,
  })
);

export const customersReview = (
  method, offset, limit, searchText, sortBy, cancelToken,
) => (
  Axios({
    method,
    url: URL.customersReview(),
    headers: getHeaders(),
    params: getParams({
      offset,
      limit,
      searchText,
      sortBy,
    }),
    cancelToken,
  })
);

export const reviewHistory = (
  method, reviewId,
) => (
  Axios({
    method,
    url: URL.reviewHistory(reviewId),
    headers: getHeaders(),
    params: getParams({
      reviewId,
    }),
  })
);

export const daily = (
  method, params, data, fid,
) => (
  Axios({
    method,
    url: URL.daily(fid),
    headers: getHeaders(),
    params: getParams(params),
    data,
  })
);

export const eventFeed = (
  method, offset, limit, storeId, cancelToken,
) => (
  Axios({
    method,
    url: URL.eventFeed(storeId),
    headers: getHeaders(),
    params: getParams({
      offset,
      limit,
    }),
    cancelToken,
  })
);

export const recommendedCategory = (
  method, categoryId, storeCode, cancelToken,
) => (
  Axios({
    method,
    url: URL.recommendedCategory(categoryId),
    headers: getHeaders(),
    params: getParams({ storeCode }),
    cancelToken,
  })
);

export const bulkAddProducts = (
  method, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.bulkAddProducts(),
    data: {
      ...data,
    },
    headers: getHeaders(),
    cancelToken,
  })
);

export const bulkRemoveProducts = (
  method, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.bulkRemoveProducts(),
    data: {
      ...data,
    },
    headers: getHeaders(),
    cancelToken,
  })
);

export const recommendedProducts = (
  method, params = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.recommendedProducts(),
    headers: getHeaders(),
    params: getParams({
      ...params,
    }),
    cancelToken,
  })
);

export const bulkAddRecommendedCatagory = async (method, data) => (
  Axios({
    method,
    url: URL.bulkAddRecommendedCatagory(),
    data,
    headers: getHeaders(),
  })
);

export const testOrder = (method, storeCode, data) => (
  Axios({
    method,
    url: URL.testOrder(storeCode),
    data,
    headers: getHeaders(),
  }));

export const specialOffer = (method, param = {}, data = {}) => (
  Axios({
    method,
    url: URL.specialOffers(),
    headers: getHeaders(),
    data,
    params: getParams(param),
  })
);

export const fraudStore = (method, storeCode, data) => (
  Axios({
    method,
    url: URL.fraudStore(storeCode),
    data,
    headers: getHeaders(),
  }));

export const couponOffer = (
  method, data, couponId, param = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.couponOffer(couponId),
    headers: getHeaders(),
    params: getParams(param),
    cancelToken,
  })
);

export const uploadImage = async (method, data) => (
  Axios({
    method,
    url: URL.imageUpload(),
    headers: getHeaders(),
    data,
  }));

export const referrerChecks = async (method, param = {}) => (
  Axios({
    method,
    url: URL.referrerChecks(),
    headers: getHeaders(),
    params: getParams(param),
  }));

export const priceUpdate = (
  method, data, param = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.priceUpdate(),
    headers: getHeaders(),
    params: getParams(param),
    data,
    cancelToken,
  })
);

export const productAddJob = (
  method, data, param = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.productAddJob(),
    headers: getHeaders(),
    params: getParams(param),
    data,
    cancelToken,
  })
);

export const storeJobStatus = (
  method, storeId, param = {},
) => (
  Axios({
    method,
    url: URL.storeJobStatus(storeId),
    headers: getHeaders(),
    params: getParams(param),
  })
);

export const productVariant = (
  method, productId, param = {}, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.productVariant(productId),
    headers: getHeaders(),
    params: getParams(param),
    data,
    cancelToken,
  })
);

export const ramstoredetail = (
  method, storeId, param,
) => (
  Axios({
    method,
    url: URL.ramstoredetail(storeId),
    headers: getHeaders(),
    params: getParams(param),
  })
);

export const ramTeam = (
  method, data,
) => (
  Axios({
    method,
    url: URL.ramTeam(),
    headers: getHeaders(),
    data,
  })
);

export const shopLogoReviews = (
  method, params, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.shopLogoReviews(),
    headers: getHeaders(),
    data,
    params: getParams(params),
    cancelToken,
  })
);

export const flashsalemeta = (
  method, params, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.flashsalemeta(),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const razorpayxmeta = (
  method,
) => (
  Axios({
    method,
    url: URL.razorpayxmeta(),
    headers: getHeaders(),
  })
);

export const rewards = (method, data, customerId) => (
  Axios({
    method,
    url: URL.rewards(),
    data,
    headers: getHeaders(getUidHeader(customerId)),
  })
);

export const retailerStory = (method, data, storyId, params = {}) => (
  Axios({
    method,
    url: URL.retailerStory(storyId),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const retailerStoryIcon = (method, data) => (
  Axios({
    method,
    url: URL.retailerStoryIcon(),
    headers: getHeaders(),
    data,
  })
);

export const productBulkUpload = (method, data, params = {}, cancelToken) => (
  Axios({
    method,
    url: URL.productBulkUpload(),
    headers: getHeaders(),
    data,
    params: getParams(params),
    cancelToken,
  })
);

export const offerClass = (method, storeId, data, cancelToken) => (
  Axios({
    method,
    url: URL.offerClass(storeId),
    headers: getHeaders(),
    data,
    cancelToken,
  })
);

export const retailerProfile = (method, storeId, params = {}, data, cancelToken) => (
  Axios({
    method,
    url: URL.retailerProfile(storeId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const couponTracker = (method, cancelToken, couponId, params = {}) => (
  Axios({
    method,
    url: URL.adminCoupon(couponId),
    headers: getHeaders(),
    params: getParams(params),
    cancelToken,
  })
);

export const queryTracker = (method, params = {}, cancelToken) => (
  Axios({
    method,
    url: URL.queryTracker(),
    headers: getHeaders(),
    params: getParams(params),
    cancelToken,
  })
);

export const customProduct = (method, cancelToken, data, params = {}) => (
  Axios({
    method,
    url: URL.customProduct(),
    headers: getHeaders(),
    params: getParams(params),
    cancelToken,
    data,
  })
);

export const refundDetail = (method, cancelToken, params = {}, data) => (
  Axios({
    method,
    url: URL.refundAdmin(),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const adminCuratedLibraryGdroup = (
  method, curatedGroupId, params = {}, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.adminCuratedLibraryGroup(curatedGroupId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const deliveryServiceability = (
  method, storeId, params = {}, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.deliveryServiceability(storeId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const adminCuratedLibraryGdroupMap = (
  method, curatedGroupMapId, params = {}, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.adminCuratedLibraryGroupMap(curatedGroupMapId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const registeredRegion = (
  method,
) => (
  Axios({
    method,
    url: URL.registeredRegion(),
    headers: getHeaders(),
  })
);

export const regionalLibrary = (
  method, params = {}, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.regionalLibrary(),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const productCategories = (
  method, params,
) => (
  Axios({
    method,
    url: URL.productCategories(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const adminStoreCuratedGroup = (
  method, storeId, params, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.adminStoreCuratedGroup(storeId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const curatedGroupBackgroundJobs = (
  method, params = {}, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.backgroundJobsdetail(),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const orderDeliverySettlement = (
  method, settlementId, params = {}, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.orderDeliverySettlement(settlementId),
    headers: getHeaders(),
    params: getParams(params),
    data,
    cancelToken,
  })
);

export const generateFundAccount = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.generateFundAccount(),
    data,
    params: getParams(params),
    headers: getHeaders(),
  })
);

export const reportCustomer = (
  method, customerId,
) => (
  Axios({
    method,
    url: URL.reportCustomer(customerId),
    headers: getHeaders(),
  })
);

export const catalogBulkUpload = (
  method, data, storeId, param = {},
) => (
  Axios({
    method,
    url: URL.catalogBulkUpload(storeId),
    data,
    params: getParams(param),
    headers: getHeaders(),
  })
);

export const orderDeliveryLogs = (
  method, orderId,
) => (
  Axios({
    method,
    url: URL.orderDeliveryLogs(orderId),
    headers: getHeaders(),
  })
);

export const strongMatchProduct = (
  method, exportId, data,
) => (
  Axios({
    method,
    url: URL.strongMatchProduct(exportId),
    data,
    headers: getHeaders(),
  })
);

export const weakMatchProduct = (
  method, exportId, data,
) => (
  Axios({
    method,
    url: URL.weakMatchProduct(exportId),
    data,
    headers: getHeaders(),
  })
);

export const noMatchProduct = (
  method, exportId, data,
) => (
  Axios({
    method,
    url: URL.noMatchProduct(exportId),
    data,
    headers: getHeaders(),
  })
);

export const customerDetails = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.customerDetails(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const whatsappNotifications = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.whatsappNotifications(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const validateOrderId = (
  method, orderId, params = {},
) => (
  Axios({
    method,
    url: URL.validateOrderId(orderId),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const lead = (
  method, leadId, data, params = {},
) => (
  Axios({
    method,
    url: URL.lead(leadId),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const leadMeta = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.leadsMeta(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const ivrStatus = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.ivrStatus(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const agentMeta = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.agentMeta(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const agentSms = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.agentSms(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const agentCall = (
  method, data, params = {}, leadId,
) => (
  Axios({
    method,
    url: URL.agentCall(leadId),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const leadBulkUpload = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.leadBulkUpload(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const alternateStoreProduct = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.alternateStoreProduct(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const libraryStoreProduct = (
  method, data,
) => (
  Axios({
    method,
    url: URL.libraryStoreProduct(),
    headers: getHeaders(),
    data,
  })
);

export const assignDeliveryPartner = (
  method, orderId = '', params = {},
) => (
  Axios({
    method,
    url: URL.assignDeliveryPartner(orderId),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const fraudStores = (
  method, storeId, data, params = {},
) => (
  Axios({
    method,
    url: URL.fraudStores(storeId),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const rerouteableStore = (
  method, orderId = '', params = {}, data = {},
) => (
  Axios({
    method,
    url: URL.rerouteableStore(orderId),
    headers: getHeaders(),
    params: getParams(params),
    data,
  })
);

export const rerouteOrderItems = (
  method, orderId = '', params = {}, data = {},
) => (
  Axios({
    method,
    url: URL.rerouteOrderItems(orderId),
    headers: getHeaders(),
    params: getParams(params),
    data,
  })
);

export const sendbirdChannels = (
  method, channelURL, data, params = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.sendbirdChannels(channelURL),
    headers: getHeaders(),
    data,
    params: getParams(params),
    cancelToken,
  })
);

export const rerouteOrder = (
  method, data, orderId = '',
) => (
  Axios({
    method,
    url: URL.rerouteOrder(orderId),
    headers: getHeaders(),
    data,
  })
);

export const groupChannelLabels = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.groupChannelLabels(),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const orderDispute = (
  method, orderId, data, params = {},
) => (
  Axios({
    method,
    url: URL.crmOrderDispute(orderId),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const orderManager = (
  method, param = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.orderManager(),
    headers: getHeaders(),
    params: getParams(param),
    cancelToken,
  })
);

export const orderManagerStatus = (
  method, orderManagerId, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.orderManagerStatus(orderManagerId),
    data,
    headers: getHeaders(),
    cancelToken,
  })
);

export const orderManagerLogs = (
  method, param = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.orderManagerLogs(),
    headers: getHeaders(),
    params: getParams(param),
    cancelToken,
  })
);

export const storeList = (
  method, data, searchText, cancelToken,
) => (
  Axios({
    method,
    url: URL.storeList(),
    headers: getHeaders(),
    data,
    cancelToken,
    params: getParams({ searchText }),
  })
);

export const panCardDetails = (
  method, param = {},
) => (
  Axios({
    method,
    url: URL.panCardDetails(),
    headers: getHeaders(),
    params: getParams(param),
  })
);

export const retailerPayments = (
  method, param = {},
) => (
  Axios({
    method,
    url: URL.retailerPayments(),
    headers: getHeaders(),
    params: getParams(param),
  })
);

export const listTags = (
  method, storeId, data,
) => (
  Axios({
    method,
    url: URL.listTags(storeId),
    data,
    headers: getHeaders(),
  })
);

export const resurrectionStores = (
  method, data, params = {}, storeId,
) => (
  Axios({
    method,
    url: URL.resurrectionStores(storeId),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const exportResurrectionStores = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.exportResurrectionStores(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const leadershipBoard = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.leadershipBoard(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const myReferral = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.myReferral(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const myOrders = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.myRecentOrders(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const campaignAnalytics = (
  method, params = {}, data,
) => (
  Axios({
    method,
    url: URL.campaignAnalytics(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const storeCommentLogs = (
  method, params = {}, data,
) => (
  Axios({
    method,
    url: URL.storeCommentLogs(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const lmsStoreCommentLogs = (
  method, params = {}, data,
) => (
  Axios({
    method,
    url: URL.lmsStoreCommentLogs(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const authRegister = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.authRegister(),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const authLogin = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.authLogin(),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const kycVerification = (
  method, params = {}, storeCode, data, cancelToken,
) => (
  Axios({
    method,
    url: URL.kycVerification(),
    headers: getHeaders(),
    params: getParams({
      ...params,
      storeCode,
    }),
    data,
    cancelToken,
  })
);

export const crmCommunication = (
  method, params = {}, data,
) => (
  Axios({
    method,
    url: URL.crmCommunication(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const abandonCustomers = (
  method, data, customerId, params = {},
) => (
  Axios({
    method,
    url: URL.abandonCustomers(customerId),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const couponCampaigns = (
  method, params = {}, data, campaignId, couponUsage,
) => (
  Axios({
    method,
    url: URL.couponCampaigns(campaignId, couponUsage),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const couponCode = (
  method, params = {}, data,
) => (
  Axios({
    method,
    url: URL.couponCode(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const productExports = (
  method, param = {}, data,
) => (
  Axios({
    method,
    url: URL.productExports(),
    headers: getHeaders(),
    params: getParams(param),
    data,
  })
);

export const executors = (
  method, data, agentId, params = {},
) => (
  Axios({
    method,
    url: URL.executors(agentId),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const agentsData = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.agentsData(),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const verificationCall = (
  method, data, storeId,
) => (
  Axios({
    method,
    url: URL.verificationCall(storeId),
    headers: getHeaders(),
    data,
  })
);

export const getPromoterBrandingLogs = (
  method, id, data, params = {},
) => (
  Axios({
    method,
    url: URL.getPromoterBrandingLogs(id),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const getBDEViewVisitsLogs = (
  method, id, data, params = {},
) => (
  Axios({
    method,
    url: URL.getBDEViewVisitsLogs(id),
    headers: getHeaders(),
    data,
    params: getParams(params),
  })
);

export const freeSampleCampaign = (
  method, campaignId, data, params = {},
) => (
  Axios({
    method,
    url: URL.freeSampleCampaign(campaignId),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const addStoreOnCampaign = (
  method, data,
) => (
  Axios({
    method,
    url: URL.addStoreOnCampaign(),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
  })
);

export const marketplaceBrands = (
  method, brandId, data, params = {},
) => (
  Axios({
    method,
    url: URL.marketplaceBrands(brandId),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const marketplaceBrandOrders = (
  method, orderId, data, params = {},
) => (
  Axios({
    method,
    url: URL.marketplaceBrandOrders(orderId),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const marketplaceBrandOrderDetails = (
  method, orderId, data, params = {},
) => (
  Axios({
    method,
    url: URL.marketplaceBrandOrderDetails(orderId),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const marketplaceBrandProducts = (
  method, productId, data, params = {},
) => (
  Axios({
    method,
    url: URL.marketplaceBrandProducts(productId),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const replaceOrderProducts = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.replaceOrderProducts(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const festiveCategory = (
  method, params = {}, id = '', data = {},
) => (
  Axios({
    method,
    url: URL.festiveCategory(id),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const uploadVideo = async (method, data) => (
  Axios({
    method,
    url: URL.videoUpload(),
    headers: getHeaders(),
    data,
  }));

export const financeSettlementDashboard = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.financeSettlementDashboard(),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const updateVpaStatus = (
  storeId, data,
) => (
  Axios({
    method: 'PATCH',
    url: URL.updateVpaStatus(storeId),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
  })
);

export const platformFeeDashboard = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.platformFeeDashboard(),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const switchSettlementMode = (
  storeId, data,
) => (
  Axios({
    method: 'PATCH',
    url: URL.switchSettlementMode(storeId),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
  })
);

export const orderManagerList = (
  method, OMTag, data, params = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.orderManagerList(OMTag),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
    cancelToken,
  })
);

export const OMLogs = (
  method, OMTag, params = {}, cancelToken,
) => (
  Axios({
    method,
    url: URL.OMLogs(OMTag),
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
    cancelToken,
  })
);

export const retailerWalletTransaction = (
  method, storeCode, data, params = {},
) => (
  Axios({
    method,
    url: URL.retailerWalletTransaction(storeCode),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const citySpecificRewards = (
  method, params = {}, data,
) => (
  Axios({
    method,
    url: URL.citySpecificRewards(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const retailerWalletInfo = (
  method, storeCode, data, params = {},
) => (
  Axios({
    method,
    url: URL.retailerWalletInfo(storeCode),
    data,
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const rerouteDisposition = (method, data, params = {}) => (
  Axios({
    method,
    url: URL.rerouteDisposition(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const orderCancellationReason = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.orderCancellationReason(),
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    params: getParams(params),
  })
);

export const transactionMeta = (method, data, params = {}, orderId = '') => (
  Axios({
    method,
    url: URL.transactionMeta(orderId),
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    data,
    params: getParams(params),
  }));

export const orderCount = (method, data, params = {}) => (
  Axios({
    method,
    url: URL.orderCount(),
    headers: getHeaders({
      'Client-Code': 'admin',
    }),
    data,
    params: getParams(params),
  }));

export const addAlternateStoreProduct = (
  method, params = {}, storeId = '', searchText = '',
) => (
  Axios({
    method,
    url: URL.alternateStoreProducts(storeId, searchText),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const getBillDetails = (
  method, data = {}, orderId = '',
) => (
  Axios({
    method,
    url: URL.billDetails(orderId),
    headers: getHeaders(),
    data,
  })
);

export const modifications = (
  method, data, orderId = '',
) => (
  Axios({
    method,
    url: URL.modifications(orderId),
    data,
    headers: getHeaders(),
  })
);

export const orderProductHistory = (
  method, params, orderId = '',
) => (
  Axios({
    method,
    url: URL.productHistory(orderId),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const rerouteOrderDisposition = (method, data, params = {}) => (
  Axios({
    method,
    url: URL.rerouteOrderDisposition(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const reListStore = (
  method, data,
) => (
  Axios({
    method,
    url: URL.reListStore(),
    data,
    headers: getHeaders(),
  })
);

export const grabOrder = (method, data, params = {}) => (
  Axios({
    method,
    url: URL.grabOrder(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const flashSaleCampaignList = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.flashSaleCampaignList(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const getCityLocality = (
  method, params = {},
) => (
  Axios({
    method,
    url: URL.cityLocality(),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const searchProduct = (
  method, productId, params = {},
) => (
  Axios({
    method,
    url: URL.searchProduct(productId),
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const flashSaleCampaign = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.flashSaleCampaign(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const storeBulkUpload = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.storeBulkUpload(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);
export const removeStore = (
  method, data, params = {},
) => (
  Axios({
    method,
    url: URL.removeStore(),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const storePinning = (
  method, data, action, params = {},
) => (
  Axios({
    method,
    url: URL.storePinning(action),
    data,
    headers: getHeaders(),
    params: getParams(params),
  })
);

export const llPlusPurchasesList = (
  method, data, params = {}, cancelToken = null,
) => (
  Axios({
    method,
    url: URL.llPlusPurchasesList(),
    data,
    headers: getHeaders(),
    params: getParams(params),
    cancelToken,
  })
);

export const storeDeliveryStatusChange = (
  method, storecode, data,
) => (
  Axios({
    method,
    url: URL.storeDeliveryStatusChange(storecode),
    data,
    headers: getHeaders(),
  })
);
