import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { CustomModal } from '../../../component/common';
import { executors } from '../../../assets/api/axios';

function AddNewExecutor(props) {
  const {
    show, onHide, agentsList, handleRequestProcessing,
  } = props;
  const [agent, setAgent] = useState(null);
  const [process, setProcess] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  function handleSubmit() {
    setLoading(true);
    executors(
      'POST',
      {
        executorName: agent.executorName,
        executorNumber: agent.executorNumber,
        executorId: agent.executorId,
        process,
      },
    ).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        onHide();
        handleRequestProcessing();
      } else {
        throw new Error();
      }
    }).catch((err) => {
      let errorMsg = 'Oops Something Went Wrong. Please Try Again';
      if (
        err
        && err.response
        && err.response.data
        && err.response.status === 400
        && err.response.data.server_message
      ) {
        errorMsg = err.response.data.server_message;
      }
      setLoading(false);
      setError(errorMsg);
    });
  }

  const body = (
    <Container className="px-5 py-4">
      <Row>
        <Col
          className="main-container py-4 px-5 fs-01"
        >
          <Row
            className="text-mid-gray"
          >
            Executor Name
            <span className="text-danger">*</span>
          </Row>
          <Row>
            <select
              className="form-control fs-0"
              onChange={(event) => {
                if (event.target.value !== '') {
                  setAgent(JSON.parse(event.target.value));
                }
              }}
            >
              <option value="">Select</option>
              {
                agentsList && agentsList.results.map((emp) => (
                  <option value={JSON.stringify(emp)} key={`${emp.executorId}-${emp.executorNumber}`}>
                    {emp.executorName}
                  </option>
                ))
              }
            </select>
          </Row>
          <Row className="text-mid-gray pt-4">
            Executor Email Id
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="text"
              value={(agent && agent.executorId) || 'Please Select Executor Name'}
              onChange={() => {}}
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            Executor Phone Number
          </Row>
          <Row>
            <input
              className="form-control fs-0"
              type="text"
              value={(agent && agent.executorNumber) || 'Please Select Executor Name'}
              onChange={() => {}}
            />
          </Row>
          <Row className="text-mid-gray pt-4">
            Select Process
          </Row>
          <Row>
            <select
              onChange={(e) => {
                if (e.target.value !== '') {
                  setProcess(e.target.value);
                }
              }}
              value={process}
              className="form-control fs-0"
            >
              <option value="">Select</option>
              <option value="ordermanagement">Order Management</option>
              <option value="Appreview_SendBird_SocialMedia_FFR_customercall">App review / Send Bird / Social Media / FFR Customer Call</option>
              <option value="Retention">Retention</option>
              <option value="welcomecall">Welcome Call</option>
              <option value="riskmanagement">Risk Management</option>
              <option value="storeturnoff">Store Turn Off</option>
              <option value="qc3team">QC 3 Team</option>
              <option value="remotetraining">Remote Training</option>
              <option value="telesales">Telesales</option>
            </select>
          </Row>
        </Col>
      </Row>
      <Row className="pt-4 d-flex justify-content-center">
        <Button
          variant="primary"
          className="fs-1 font-weight-bold p-2 border-radius-8"
          disabled={!agent || !process || loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Add Executor
        </Button>
      </Row>
      {
        loading && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center"
          >
            <Spinner
              variant="primary"
              animation="border"
            />
          </Row>
        )
      }
      {
        !loading && error && (
          <Row
            className="pt-2 d-flex align-item-center
              justify-content-center text-danger"
          >
            {error}
          </Row>
        )
      }
    </Container>
  );

  return (
    <CustomModal
      show={show}
      title="Add Executor"
      body={body}
      onHide={onHide}
      autoSize
      closeButton
    />
  );
}

AddNewExecutor.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  agentsList: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  handleRequestProcessing: PropTypes.func.isRequired,
};

export default AddNewExecutor;
