import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Svg } from '../../../../component/common';
import { Constant } from '../../../../utilities';

export default function UpdateQty(props) {
  const { onUpdateQty, rowData } = props;
  return (
    <div className="d-flex">
      <div>
        <Button
          variant="link"
          onClick={() => { onUpdateQty(rowData, 0); }}
          disabled={rowData.isFreeSample || rowData.isFlashSaleProduct}
          className="pl-0"
        >
          <Svg
            svg="trash"
            width={24}
            height={24}
            stroke={(rowData.isFreeSample || rowData.isFlashSaleProduct)
              ? Constant.Color.PRIMARY_LIGHT : Constant.Color.PRIMARY}
          />
        </Button>
      </div>
      <div>
        {
          rowData.quantity > 0 ? (
            <div
              className={` ${(rowData.isFreeSample || rowData.isFlashSaleProduct) ? 'border border-primary-light text-primary-light' : 'border border-primary text-primary'} fs-02 font-weight-bold d-flex justify-content-center align-items-center`}
            >
              <Button
                variant="link"
                disabled={rowData.isFreeSample || rowData.isFlashSaleProduct}
                onClick={() => { onUpdateQty(rowData, rowData.quantity - 1); }}
              >
                -
              </Button>
              <span>
                {rowData.quantity}
              </span>
              <Button
                variant="link"
                disabled={rowData.isFreeSample || rowData.isFlashSaleProduct}
                onClick={() => { onUpdateQty(rowData, rowData.quantity + 1); }}
              >
                +
              </Button>
            </div>
          ) : (
            <Button
              variant="link"
              onClick={() => { onUpdateQty(rowData, 1); }}
            >
              ADD
            </Button>
          )
        }
      </div>
    </div>
  );
}

UpdateQty.propTypes = {
  onUpdateQty: PropTypes.func.isRequired,
  rowData: PropTypes.shape({
    quantity: PropTypes.number,
    isFreeSample: PropTypes.bool,
    isFlashSaleProduct: PropTypes.bool,
  }).isRequired,
};
